 export const skillsData = [
    'HTML',
    'CSS',
    'Python',
    'Java',
    'Photoshop',
    'Figma',
    'Javascript',
    'CSS',
    'React',
    'Bootstrap',
    'Wordpress' ,
    'Pytorch',
    'AWS',
   'Figma',
   'Canva',
   'Illustrator',
   'Microsoft Office',
   'Figma',
   'Canva',


]

// Choose your skills from below. Make sure it's in the same format and spelled correctly.
// Couldn't find the required skills? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new


// AVAILABLE SKILLS

/* 
    HTML
    CSS
    JS 
    React
    Next JS
    Nuxt JS
    Node JS
    Vue
    Angular
    Docker
    Photoshop
    'Illustrator',
    Svelte
    GCP
    'Azure',
    Fastify
    Haxe
    Ionic
    Markdown
    'Microsoft Office',
    Picsart
    Sketch
    Unity
    WolframAlpha
    'Adobe XD',
    After Effects
    'Bootstrap'.
    Bulma
    CapacitorJs
    Coffeescript
    MemSQL
    C
    C++
    C#
    'Python',
    'Java'.
    Julia
    Matlab
    Swift
    Ruby
    'Kotlin',
    Go
    PHP
    Flutter
    Dart
    Typescript
    Swift
    Git
    'Figma',
    'Canva',
    Ubuntu
    Bootstrap
    MongoDB
    Tailwind
    ViteJS
    VuetifyJS
    MySQL
    PostgreSQL
    'AWS',
    Firebase
    Blender
    Premiere Pro
    Adobe Audition
    Deno
    Django
    Gimp
    Graphql
    Lightroom
    MaterialUI
    Nginx
    Numpy
    OpenCV
    'Pytorch',
    Selenium
    Strapi
    Tensorflow
    Webex
    'Wordpress',
    'Pytorch',
    'AWS',
   'Figma',
   'Canva',
*/
