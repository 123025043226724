import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Virtualiec',
        projectDesc: 'Virtualiec is creating new dimensions in the field of the metaverse in Pakistan, a virtual reality experience that enables people to interact and conduct business in a digital environment.',
        tags: ['React', 'CSS', 'Material Ui'],
        code: '',
        demo: '',
        image: one
    },
    {
        id: 2,
        projectName: 'Edtech System',
        projectDesc: 'YEESP (Youth Education and Employment Support Program) a low cost Education and Freelancing platform, which aims to create employment opportunities by providing quality education on digital, professional and interpersonal skills.',
        tags: ['Django', 'Tailwind CSS'],
        code: '',
        demo: '',
        image: two
    },
    {
        id: 3,
        projectName: 'LMS',
        projectDesc: 'Python based Learning Management System.',
        tags: ['Django', 'CSS', 'Python'],
        code: '',
        demo: '',
        image: three
    },
    {
        id: 4,
        projectName: 'E-Commerce',
        projectDesc: 'A E-commerce application',
        tags: ['Magento', 'Firebase'],
        code: '',
        demo: '',
        image: four
    },
    {
        id: 5,
        projectName: 'Bankin Products',
        projectDesc: 'Banking  Aplication Development Experince ',
        tags: ['BNPL, Corporate Financing, SME Financing, POS, Cards Payment, Islamic Financing, Islamic Finance Products'],
        code: '',
        demo: '',
        image: five
    },
    {
        id: 6,
        projectName: 'Digital Transformation',
        projectDesc: 'Digital Transformation of all manual processes of the companies',
        tags: ['React', 'Redux', 'Bootstrap'],
        code: '',
        demo: '',
        image: six
    },

]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/